// import {markRaw} from "vue";
import {useDialog} from "primevue/usedialog";
import DialogAccessToken from "@/components/Trainee/DialogAccessToken.vue";
import JobForm from "@/components/Trainee/Job/Form.vue";
import QualificationForm from "@/components/Trainee/Qualification/Form.vue";
import TraineeEdit from "@/components/Trainee/Edit.vue";
import DialogActivate from "@/components/Trainee/DialogActivate.vue";
import DialogResendEmailActivation from "@/components/Trainee/DialogResendEmailActivation.vue";

export function dialogsTrainees(dynamicDialogDefaults) {

    const dialog = useDialog()

    const traineeEdit = (trainee = null, params) => {
        return dialog.open(TraineeEdit, {
            ...dynamicDialogDefaults,
            props: {
                ...dynamicDialogDefaults.props,
                header: trainee ? "Modifier un stagiaire" : "Ajouter un stagiaire"
            },
            data: {
                item: trainee,
            },
            ...params,
        });
    }
    const traineeActivate = (trainee, params) => {
        return dialog.open(DialogActivate, {
            ...dynamicDialogDefaults,
            props: {
                ...dynamicDialogDefaults.props,
                header: 'Statut du stagiaire',
            },
            data: {trainee},
            ...params,
        });
    }
    const traineeResendEmailActivation = (trainee, params) => {
        return dialog.open(DialogResendEmailActivation, {
            ...dynamicDialogDefaults,
            props: {
                ...dynamicDialogDefaults.props,
                header: "Activation de l'adresse E-mail",
            },
            data: {trainee},
            ...params,
        });
    }
    const traineeAccessTokenView = (params) => {
        return dialog.open(DialogAccessToken, {
            ...dynamicDialogDefaults,
            props: {
                ...dynamicDialogDefaults.props,
                header: 'Partager votre Passeport',
                dismissableMask: true,
            },
            /*templates: {
                header: markRaw(DialogAccessTokenHeader),
            },*/
            data: {},
            ...params,
        });
    }
    const traineeJobForm = (trainee, job, params) => {
        return dialog.open(JobForm, {
            ...dynamicDialogDefaults,
            props: {
                ...dynamicDialogDefaults.props,
                header: (job && job.id ? 'Modifier' : 'Ajouter') + ' un emploi ou un stage',
            },
            data: {trainee, item: job || null},
            ...params,
        });
    }
    const traineeQualificationForm = (trainee, job, params) => {
        return dialog.open(QualificationForm, {
            ...dynamicDialogDefaults,
            props: {
                ...dynamicDialogDefaults.props,
                header: (job && job.id ? 'Modifier' : 'Ajouter') + ' une formation ou un diplôme',
            },
            data: {trainee, item: job || null},
            ...params,
        });
    }

    return {
        traineeEdit,
        traineeActivate,
        traineeResendEmailActivation,
        traineeAccessTokenView,
        traineeJobForm,
        traineeQualificationForm
    }
}
