import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import duration from "dayjs/plugin/duration";
import plugin from "@/includes/dayjs/plugin"
import "dayjs/locale/fr"

export function useDate() {
    dayjs.extend(relativeTime)
    dayjs.extend(duration)
    dayjs.extend(isSameOrAfter)
    dayjs.extend(isSameOrBefore)

    dayjs.extend(plugin)

    dayjs.locale('fr')
    return dayjs
}
