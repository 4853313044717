import {onMounted, ref, watch} from 'vue'
import {useMain} from "@/stores/main";
import {useAuth} from "@/stores/auth";

export function useAppInit() {
    const mainStore = useMain()
    const authStore = useAuth()
    const initializing = ref(true)

    const init = async () => {
        initializing.value = true

        await authStore.authUserInit()
        await mainStore.preloadAppData()

        initializing.value = false

        // will update logged user
        if(authStore.authenticated && mainStore.isNetworkConnected) {
            authStore.authUserFetch()
        }
    }

    onMounted(async () => {
        await init()
    })

    watch(
        () => mainStore.connectivityStatus,
        async () => {
            await mainStore.preloadAppData()
        }
    )

    return {
        initializing,
        init,
    }
}
