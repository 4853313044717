import {useDialog} from "primevue/usedialog";
import OrganizationView from "@/components/Organization/View.vue";
import OrganizationEdit from "@/components/Organization/Edit.vue";
import {useAuth} from "@/stores/auth";
export function dialogsOrganizations(dynamicDialogDefaults) {

    const dialog = useDialog()

    const organizationView = (item, params={}) => {

        return dialog.open(OrganizationView, {
            ...dynamicDialogDefaults,
            props: {
                ...dynamicDialogDefaults.props,
                header: 'Organisme',
                dismissableMask: true,
            },
            data: {
                item,
            },

            ...params,
        });
    }

    const organizationEdit = (item, params={}) => {
       return dialog.open(OrganizationEdit, {
            ...dynamicDialogDefaults,
            props: {
                ...dynamicDialogDefaults.props,
                header: item.id === useAuth().authUser.organization.id ? "Modifier votre organisme" : "Modifier un organisme"

            },
            data: {
                item,
            },

           ...params,
        })
    }

    return {
        organizationView,
        organizationEdit,
    }
}

