import {markRaw} from "vue";
import {useDialog} from "primevue/usedialog";

import TraineeSkill from "@/components/Trainee/Skill.vue";
import TraineeSkillHeader from "@/components/Trainee/SkillHeader.vue";

export function dialogsSkills(dynamicDialogDefaults) {

    const dialog = useDialog()

    const skillsView = (skill, trainee, params) => {
        return dialog.open(TraineeSkill, {
            ...dynamicDialogDefaults,
            props: {
                ...dynamicDialogDefaults.props,
                header: 'Compétence',
                dismissableMask: true,
            },
            templates: {
                header: markRaw(TraineeSkillHeader),
            },
            data: {
                skill,
                trainee,
            },
            ...params,
        });
    }


    return {
        skillsView
    }
}
