import {onMounted, onUnmounted, ref, watch} from 'vue'
import {useMain} from "@/stores/main";

import {useToast} from 'primevue/usetoast'

export function useConnectivityCheck() {
    const mainStore = useMain()
    const lastStatus = ref(null)
    const reloadKey = ref(0)

    const toast = useToast()
    const connectivityUpdate = () => {
        if (mainStore.isNetworkConnected) {
            toast.removeGroup('ns')
            if (lastStatus.value !== mainStore.connectivityStatus) {
                toast.add({
                    severity: 'success',
                    group: 'ns',
                    summary: 'En ligne',
                    detail: "Vous êtes à nouveau en ligne.",
                    life: 5000
                })
            }
            reloadKey.value++
        } else {
            let detail = 'Le serveur ne répond pas correctement, veuillez réessayer plus tard.'

            if (mainStore.connectivityStatus === 'OFFLINE') {
                detail = 'Vous êtes hors-ligne. Vérifiez votre connexion.'
            }
            if (mainStore.connectivityStatus === 'ERR_NETWORK' || mainStore.connectivityStatus === null) {
                detail = 'Vous ne semblez pas connecté à Internet ou le serveur est indisponible.'
            }
            if (mainStore.connectivityStatus === 503) { // maintenance, no toast
                detail = null
            }

            const differentNButSame = ['OFFLINE', 'ERR_NETWORK'];
            if (detail && lastStatus.value !== mainStore.connectivityStatus &&
                !(differentNButSame.includes(lastStatus.value) && differentNButSame.includes(mainStore.connectivityStatus))
            ) { // show toast without duplicate
                lastStatus.value = mainStore.connectivityStatus
                toast.removeGroup('ns')
                toast.add({
                    severity: 'error',
                    group: 'ns',
                    summary: 'Erreur réseau',
                    detail,
                    life: 5000
                })
            }

        }
    }

    const visibilityChanged = async () => {
        if (document.hidden) {
            mainStore.stopIntervalCheck()
        } else {
            await mainStore.checkConnectivity()
            mainStore.startIntervalCheck()
        }
    }

    onMounted(async function () {
        await mainStore.checkConnectivity()
        mainStore.startIntervalCheck()

        document.addEventListener('visibilitychange', visibilityChanged);
    })

    onUnmounted(async function () {
        console.log('unmounted')
        //document.removeEventListener('visibilitychange', visibilityChanged);
    })

    watch(
        () => mainStore.connectivityStatus,
        () => {
            connectivityUpdate()
        }
    )

    return {lastStatus, reloadKey}
}
